import bgTeam from "assets/images/bg-team.png"
import styled from 'styled-components'
import { breakpointsMedias } from "configs/breakpoints"
import Footer from "components/Footer/Footer"
import bgIntro from "assets/images/bg-intro.png"

const Cookies = () => {
    return <Wrap id="team">
        <div className="tittle-row">
            <span className="size-4 color-white text-center">Cookie Policy&nbsp;<span className="size-4 text-gradient">Metatech Ltd.</span></span>
        </div>
        <div className="container">
            <div className="team-pvc">
                <p>We use permanent cookies to improve the user interface and personalize the display of our services. We also use so-called session cookies which are automatically deleted when you close your browser. You can adjust the settings of your browser such that it informs you if cookies are placed on your device. This makes the use of cookies transparent for you. We use the following five categories of cookies on our website:</p>
                <p>•    Required cookies </p>
                <p>•    Performance cookies</p>
                <p>•    Functionality cookies</p>
                <p>•    Personalization cookies</p>
                <p>•    Targeting cookies</p>
                <p>Required cookies: These cookies are required to ensure that you can navigate on the website and use its specific functions. For example, such cookies are used when products are placed in the shopping basket to enable you to leave the website and continue to access the products in your shopping basket on your return.</p>
                <p>Performance Cookies: These cookies record information on how visitors use a website, e.g. which pages they call up most frequently and whether they receive error messages from websites. These cookies do not collect any data on the basis of which users can be identified. All information collected with the aid of these cookies is anonymous and serves exclusively to improve the functionality and service of the Website. We therefore use performance cookies to prepare statistics on how our website is used and to gauge the effectiveness of our advertising campaigns.
                </p>
                <p>Functionality cookies: These serve to provide the services you request, e.g. if you would like to see a video. Functionality cookies also serve to remember settings which you make on the website (e.g. location). These cookies are not able to track your browsing on other websites and cannot follow where you have surfed on the internet other than on our Website. Without these cookies, we are unable to provide certain services requested by you.
                </p>
                <p>We use Google Analytics, a popular web analytics service provided by Google, Inc. Google Analytics uses cookies to help us to analyse how users use the site. It counts the number of visitors and tells us things about their behaviour overall – such as the typical length of stay on the site or the average number of pages a user views.
                </p>
                <p>The information generated by the cookie about your use of our website (including your IP address) will be transmitted to and stored by Google on servers in the United States. Google will use this information for the purpose of evaluating your use of our website, compiling reports on website activity and providing other services relating to website activity and internet usage.
                    Google may also transfer this information to third parties where required to do so by law, or where such third parties process the information on Google’s behalf. Google undertakes not to associate your IP address with any other data held by Google. You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this website. By using this website, you consent to the processing of data about you by Google in the manner and for the purposes set out above.
                </p>
                <p>Most browsers accept cookies automatically but allow you to disable them. You can reset your browser to refuse all cookies or to indicate when a cookie is being sent. Some features of the Services may not work properly without cookies. Some web browsers (including Safari, Internet Explorer, Firefox, and Chrome) incorporate a “Do Not Track” (“DNT”) or similar feature that signals to websites that a User does not want to have his or her online activity and behaviour tracked. If a website that responds to a particular DNT signal receives the DNT signal, the browser can block that website from collecting certain information about the browser’s User. Not all browsers offer a DNT option and DNT signals are not yet uniform. Because there is not yet an accepted standard for how to respond to browser DNT signals, we do not currently respond to them. You also can typically remove and reject Cookies or Local Storage from the Services with your browser settings. Many browsers are set to accept Cookies and Local Storage until you change your settings. If you remove or reject Cookies or Local Storage, it could affect how the Services work for you. In all circumstances, we may perform the foregoing information collection practices directly or use a third-party vendor to perform these functions on our behalf. Also, if you access the Website or the Services from a social networking service, we may share information with such social networking service in accordance with this Privacy Policy and to the extent permitted by your agreement with such social networking service and its privacy settings.
                </p>
            </div>
        </div>
        <div className="footer">
            <Footer />
        </div>
    </Wrap >

}

export default Cookies
const Wrap = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 120px 0;
    margin-top: -120px;
    background-image: url(${bgTeam});
    background-size: cover;
    background-position: bottom center;
    height: fit-content;
    .footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: fit-content;
    }
    .tittle-row {
        margin-bottom: 30px;
        text-align: center;
    }
    > .container {
        display: flex;
        align-items: center;
        flex-direction: column;
        p {
            margin-top: 10px;
        }
    }
    ${breakpointsMedias.max1199} {
        margin-top: 0;
    }
    ${breakpointsMedias.max991} {
        background-image: url(${bgIntro});
        background-position: center;
    }
    ${breakpointsMedias.max767} {
        padding: 72px 0 130px 0;

    }
`

