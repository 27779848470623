/* eslint-disable import/no-anonymous-default-export */
export default {
    colorWhite: "#EEEEEE",
    colorPrimary: "#F2BD02",
    colorGray: "#E6E6E6",
    colorBorder: "#828282",
    bgPrimary: "#000000",
    lightGray: "#828282",
    lightWhite: "#E6E6E6",
    colorBG: "#0C0B0D",
    colorGold: "#F2BD02",
    colorBlack: "#0B0B0B",
    colorGreen: "#53D4A1",
}