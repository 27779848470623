import iconLogo from 'assets/images/logo.svg'
import iconLogo2 from 'assets/images/logo-2.svg'
import iconLogo3 from 'assets/images/logo-3.svg'
import { breakpointsMedias } from 'configs/breakpoints'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

interface ILogo {
    type: 1 | 2 | 3
}

const dataLogo = [iconLogo, iconLogo2, iconLogo3]

const Logo = ({ type }: ILogo) => {
    return (
        <Wrap>
            <Link to="" className="logo">
                <img className='' src={dataLogo[type - 1]} alt="" />
            </Link>
        </Wrap>
    )
}

export default Logo

const Wrap = styled.div`
    width: 100%;
    height: fit-content;
    cursor: pointer;
    .logo {
        width: 100%;
        height: fit-content;
        > img {
            width: 100%;
            height: auto;
        }
    }
`