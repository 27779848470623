import bgTeam from "assets/images/bg-team.png"
import styled from 'styled-components'
import { breakpointsMedias } from "configs/breakpoints"
import Footer from "components/Footer/Footer"
import bgIntro from "assets/images/bg-intro.png"

const Privacy = () => {
    return <Wrap id="team">
        <div className="tittle-row">
            <span className="size-4 color-white text-center">Privacy&nbsp;<span className="size-4 text-gradient">Policy</span></span>
        </div>
        <div className="container">
            <p>
                Company is committed to the protection of personal information. As a part of this commitment, our Terms of Use and Privacy Policy herein-below describes how we use personal information, and the circumstances under which we may share, retain or destroy this information. This Privacy Policy was established within the context, and applies to the users of our services (Company Website, Company App, Custom Websites or Apps created by Company), features, content or applications offered by the Company, and any other websites or apps operated, hosted, or maintained by the Company. Our Privacy Policy is applicable to not only the uses of the Company website and Service, but also all interactions between you and the Company, as there is the possibility that during such interaction, we may acquire your personal information. All users, end-users, and third parties are notified that such policies are applicable only to the interaction between Company and the individual user or the customer of the Company.<br />
                Please read this privacy policy carefully by accessing or using the website/mobile application. You agree to be bound by the terms described herein. If you do not agree to all of these terms, please do not use the website/mobile application. Using the website/mobile application will be considered as a deemed acceptance of this policy.
            </p>
            <strong className="size-2 color-gold">Content &amp; Purpose</strong><br />
            <p>
                This privacy policy <strong className="size-2 color-gold">(“Privacy Policy”)</strong> applies to your use of the domain name www.metastrike.io, an internet-based portal, (hereinafter, referred to as “We” or “Our” or “Us” or “Company”). The domain name and the mobile application are collectively referred to as “Website”.<br />
                Our Website provides a web-based platform that gives our restaurant business partners a single integrated interface for managing all online and in-person orders (hereinafter referred to as <strong className="size-2 color-gold">“Services”</strong>). The Services would be made available to such persons who have agreed to become buyers or users on the Website after obtaining due registration/accepting necessary terms and conditions, in accordance with the procedure as determined by us, from time to time (referred to as <strong className="size-2 color-gold">“you”</strong> or <strong className="size-2 color-gold">“Your”</strong> or <strong className="size-2 color-gold">“Yourself”</strong> or <strong className="size-2 color-gold"> “User”</strong>, which terms shall also include persons who are accessing the Website merely as visitors).
            </p>
            <p>We have implemented reasonable security practices and procedures that are commensurate with the information assets being protected and with the nature of our business. While we try our best to provide security that is commensurate with the industry standards, because of the inherent vulnerabilities of the internet, we cannot ensure or warrant the complete security of all information that is being transmitted to us by you.
            </p>
            <p>For the purpose of providing the Services and for other purposes identified in this Privacy Policy, we will be required to collect and host certain data and information of the Users. We are committed to protecting the Personal Information (as defined below) of the Users and take all reasonable precautions for maintaining the confidentiality of the User’s Personal Information. This Privacy Policy has been designed and developed to help you understand the following:
            </p>
            <p>
                1. The type of Personal Information (including Sensitive Personal Data or Information) that we collect from the Users;<br />
                2. The purpose of collection, means and modes of usage of such Personal Information by the Company;<br />
                3. How and to whom the Company will disclose such information;<br />
                4. How the Company will protect the Personal Information including Sensitive Personal Data or Information that is collected from the Users; and<br />
                5. How Users may access and/or modify their Personal Information.<br />
                This Privacy Policy shall apply to the use of the Website by all Users. Accordingly, a condition of each User’s use of and access to the Website and to the other services provided by the Company to Users is their acceptance of this Privacy policy. Any User is required to read and understand the provisions set out herein prior to submitting any Sensitive Personal Data or Information to the Company, failing which they are required to leave the Website immediately.
            </p>
            <strong className="size-2 color-gold">Personal Information</strong><br />
            <p><strong className="size-2 color-gold">“Personal Information” </strong> means any information that relates to a natural person, which, either directly or indirectly, in combination with other information available with the Company, is capable of identifying the person concerned.</p>
            <p>
                <strong className="size-2 color-gold">“Sensitive Personal Data or Information” </strong>means Personal Information of any individual relating to password; financial information such as bank account or credit card or debit card or other payment instrument details; physical, physiological and mental health condition; sexual orientation; medical records and history; biometric information; any detail relating to the above as provided to or received by the Company for processing or storage. However, any data/information relating to an individual that is freely available or accessible in public domain or furnished under the Right to Information Act, 2005 or any other law shall not qualify as Sensitive Personal Data or Information.
            </p>
            <strong className="size-2 color-gold">Types of Personal Information collected by the Company</strong><br />
            <p>
                A User may have limited access to the Website and utilize some of the Services provided by the Company without creating an account on the Website. Unregistered Users can access some of the information and details available on the Website. In order to have access to all the features and benefits on our Website, a User may be required to first create an account on our Website. As part of the registration process, we may collect the following categories of Personal Information from the Users (hereinafter collectively referred to as “User Information”):
            </p>
            <ul>
                <li>Name;</li>
                <li>User ID;</li>
                <li>Ethereum wallet address</li>
                <li>Email address;</li>
                <li>Address (including country and ZIP/postal code);</li>
                <li>Gender;</li>
                <li>Age;</li>
                <li>Phone Number;</li>
                <li>Password chosen by the User;</li>
                <li>Valid financial account information; and</li>
                <li> Other details as the User may volunteer.</li>
            </ul>
            <p>In order to avail the Services, the Users may be required to upload/share certain documents, on the Website and/or e-mail the same to the Company in accordance with the Website’s terms of use and such documents will be stored/disclosed by the Company only in the manner specified in this Privacy Policy and the Terms of Use. The term “User Information” shall also include any such documents uploaded or otherwise provided by Users. We may keep records of telephone calls received and made for making inquiries, orders, or other purposes for the purpose of administration of Services.
            </p>
            <strong className="size-2 color-gold">Internet Use &amp; Cookie Policy</strong><br />
            <p>We may also receive and/or hold information about the User’s browsing history including the URL of the site that the User visited prior to visiting the Website as well as the Internet Protocol (IP) address of each User’s computer (or the proxy server a User used to access the World Wide Web), User’s computer operating system and type of web browser the User is using as well as the name of User’s ISP. The Website uses temporary cookies to store certain data (that is not Sensitive Personal Data or Information) that is used by us for the technical administration of the Website, research and development, and for User administration.
            </p>
            <ul>
                <li>We do not knowingly collect Personal Information from children.
                </li>
                <li>We may in future include other optional requests for information from the User including through user surveys in order to help us customize the Website to deliver personalized information to the User and for other purposes are mentioned herein. Such information may also be collected in the course of contests conducted by us. Any such additional Personal Information will also be processed in accordance with this Privacy Policy.</li>
            </ul>
            <strong className="size-2 color-gold">Purposes for which the Company may use the Information</strong><br />
            <p>We will retain User Information only to the extent it is necessary to provide Services to the Users. The information, which we collect from you, may be utilized for various business and/or regulatory purposes including for the following purposes:</p>
            <p>
                1. Registration of the User on the Website;<br />
                2. Processing the User’s orders/requests and provision of Services (including provision of safe Services);<br />
                3. Completing transactions with Users effectively and billing for the products/Services provided;<br />
                4. Technical administration and customization of Website;<br />
                5. Ensuring that the Website content is presented to the Users in an effective manner;<br />
                6. Delivery of personalized information and target advertisements to the User;<br />
                7. Improvement of Services, features and functionality of the Website;<br />
                8. Research and development and for User administration (including conducting user surveys);<br />
                9. Non-personally identifiable information, exclusively owned by the Company may be used in an aggregated or non-personally identifiable form for internal research, statistical analysis and business intelligence purposes including those for the purposes of determining the number of visitors and transactional details, and we may sell or otherwise transfer such research, statistical or intelligence data in an aggregated or non-personally identifiable form to third parties and affiliates;<br />
                10. Dealing with requests, enquiries, complaints or disputes and other customer care related activities including those arising out of the Users’ request of the Services and all other general administrative and business purposes;<br />
                11. Communicate any changes in our Services or this Privacy Policy or the Terms of Use to the Users; Company reserves the right to change this Privacy Policy from time to time. The most current version of the policy will govern our use of your personal information. In the even that Company, at its sole discretion, determines that updates to the Privacy Policy constitute a material change, we may inform you of such changes in a notice published via the Services or send an email to the email address associated with your User account. Notwithstanding the foregoing, Company is under no obligation to notify a User regarding changes to this policy, and thus Users should periodically visit this page to review the then current Policy to which you are bound.<br />
                12. Verification of identity of Users and perform checks to prevent frauds; and<br />
                13. Investigating, enforcing, resolving disputes and applying our Terms of Use and Privacy Policy, either ourselves or through third party service providers.
            </p>
            <p>
                We comply with Children's Online Privacy Protection Act (COPPA) and do not target our Website or our products or merchandise for sale on our Website to children, and our Website does not seek to collect contact information from children under the age of 13. However, should we become aware that a child under the age of 13 has provided us with personal information; Company will take the necessary steps to remove such information and terminate the child’s account.<br />
                If you are under the age of 13, please do not, submit any personal information to our Website, or ask us to email you. If you are under the age of 18, you should get permission from a parent or guardian before you email our Website, contact our Website, attempt to use our Website, submit information to our Website, or ask us to email you.
            </p>
            <strong className="size-2 color-gold">Disclosure and Transfer of User’s Personal Information</strong><br />
            <p>
                We may need to disclose/transfer User’s Personal Information to the following third parties for the purposes mentioned in this Privacy Policy and the Terms of Use:<br />
                Government institutions/authorities to the extent required:
            </p>
            <p>
                1. Under the laws, rules, and regulations and/or under orders of any relevant judicial or quasi-judicial authority;<br />
                2. To protect and defend the rights or property of the Company;<br />
                3. To fight fraud and credit risk;<br />
                4. To enforce the Company’s Terms of Use (to which this Privacy Policy is also a part); or<br />
                5. When the Company, in its sole discretion, deems it necessary in order to protect its rights or the rights of others.
            </p>
            <p>
                If otherwise required by an order under any law for the time being in force including in response to inquiries by Government agencies for the purpose of verification of identity, or for prevention, detection, investigation including cyber incidents, prosecution, and punishment of offenses.<br />
                The Company makes all User’s Personal Information accessible to its employees and data processors only on a need-to-know basis. All the employees and data processors, who have access to, and are associated with the processing of User Information, are obliged to respect its confidentiality.<br />
                Non-personally identifiable information may be disclosed to third party ad servers, ad agencies, technology vendors and research firms to serve advertisements to the Users. The Company may also share its aggregate findings (not specific information) based on information relating to the User’s internet use to prospective investors, strategic partners, sponsors and others in order to help growth of the Company’s business.<br />
                We may also disclose or transfer the User’s Personal Information, to another third party as part of reorganization or a sale of the assets or business of the Company. Any third party to which the Company transfers or sells its assets will have the right to continue to use the Personal Information and/or other information that a User provides to us.
            </p>
            <strong className="size-2 color-gold">Links to Third-Party</strong><br />
            <p>The links to third-party advertisements, third party websites or any third party electronic communication services (referred to as “<strong className="size-2 color-gold">Third Party Links</strong>”) may be provided on the Website which are operated by third parties and are not controlled by, or affiliated to, or associated with the Company unless expressly specified on the Website. If you access any such “Third Party Links”, we request you review the Third Party’s privacy policy. We are not responsible for the policies or practices of “Third Party Links”.</p><br />
            <strong className="size-2 color-gold">Security Practices and Procedures</strong><br />
            <p>
                The Company adopts reasonable security practices and procedures to include technical, operational, managerial and physical security control measures in order to protect the Personal Information in its possession from loss, misuse and unauthorized access, disclosure, alteration and destruction.<br />
                You hereby acknowledge that the Company is not responsible for any intercepted information sent via the internet, and you hereby release us from any and all claims arising out of or related to the use of intercepted information in any unauthorized manner.
            </p>
            <strong className="size-2 color-gold">Retention</strong><br />
            <p>We have personal information retention processes designed to retain Users’ Personal Information for no longer than necessary for the purpose for which it was collected or provided to us or to otherwise meet legal requirements.</p><br />
            <strong className="size-2 color-gold">User’s rights in relation to their personal information collected by the Company</strong><br />
            <p>
                All the information provided to the Company by a User, including Sensitive Personal Data or Information, is voluntary. User has the right to withdraw his/her/its consent at any time, in accordance with the terms of this Privacy Policy and the Terms of Use, but please note that withdrawal of consent will not be retroactive.<br />
                Users can access, modify, correct and delete the Personal Information provided by them which has been voluntarily given by the User and collected by the Company in accordance with this Privacy Policy and Terms of Use. However, if the User updates his/her information, the Company may keep a copy of the information which User originally provided to the Company in its archives for User documented herein. In case the User seeks to update or correct his/her Personal Information, the User may exercise these rights by emailing the Company at ________________ and communicate the change(s) for updating the Company’s records.<br />
                In case the User does not provide his/her information or consent for usage of Personal Information or subsequently withdraws his/her consent for usage of the Personal Information so collected, the Company reserves the right to discontinue the Services for which the said information was sought.
            </p>
            <strong className="size-2 color-gold">Complaints and Grievance Redressal</strong><br />
            <p>
                Any complaints, abuse, or concerns with regards to content and or comment or breach of these terms shall be immediately informed to the designated Grievance Officer as mentioned below via in writing or through email signed with the electronic signature.
            </p>
            <strong className="size-2 color-gold">Change to this Privacy Policy</strong><br />
            <p>
                This Privacy Policy may be updated periodically to reflect changes to comply with applicable privacy laws. The revised Privacy Policy will be posted on this Website. We strongly encourage you to please refer to this Privacy Policy often for the latest information about our personal information practices.
            </p>
            <strong className="size-2 color-gold">Contact Us</strong>
            <p>
                Please contact the Metatech Ltd at  sondinh@metatech.io  with any questions or concerns that you may have about the Privacy Policy or the manner in which your personal information is collected, used, disclosed and otherwise managed by the Company.
            </p>
        </div>
        <div className="footer">
            <Footer />
        </div>
    </Wrap>

}

export default Privacy
const Wrap = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 120px 0;
    margin-top: -120px;
    background-image: url(${bgTeam});
    background-size: cover;
    background-position: bottom center;
    height: fit-content;
    .footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: fit-content;
    }
    .tittle-row {
        margin-bottom: 30px;
        text-align: center;
    }
    > .container {
        display: flex;
        /* align-items: center; */
        flex-direction: column;
        p {
            margin-top: 10px;
            margin-bottom: 5px;
        }
    }
    ${breakpointsMedias.max1199} {
        margin-top: 0;
    }
    ${breakpointsMedias.max991} {
        background-image: url(${bgIntro});
        background-position: center;
    }
    ${breakpointsMedias.max767} {
        padding: 72px 0 130px 0;

    }
`

