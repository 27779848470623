import frameTeam from "assets/images/frame-team.png"
import imgTeam1 from "assets/images/team-1.png"
import imgTeam2 from "assets/images/team-2.png"
import imgTeam3 from "assets/images/team-3.png"
import imgTeam4 from "assets/images/team-4.png"
import imgTeam5 from "assets/images/team-5.png"
import imgTeam6 from "assets/images/team-6.png"
import imgTeam7 from "assets/images/team-7.png"
import imgTeam8 from "assets/images/team-8.png"
import imgTeam9 from "assets/images/team-9.png"
import imgTeam10 from "assets/images/team-10.png"
import imgTeam11 from "assets/images/team-11.png"
import imgTeam12 from "assets/images/team-12.png"
import imgTeam13 from "assets/images/team-13.png"
import imgTeam14 from "assets/images/team-14.png"
import bgTeam from "assets/images/bg-team.png"
import styled from 'styled-components'
import { breakpointsMedias } from "configs/breakpoints"
import Footer from "components/Footer/Footer"
import bgIntro from "assets/images/bg-intro.png"

const Team = () => {
    const dataFeature = [{
        img: imgTeam1,
        name: "Peter Nguyen",
        job: "Founder & CEO"
    }, {
        img: imgTeam2,
        name: "Edward Pham",
        job: "COO&Game Director"
    }, {
        img: imgTeam3,
        name: "Oanh Nguyễn",
        job: "Project Manager"
    }, {
        img: imgTeam4,
        name: "Felix Trần",
        job: "RND Director"
    }, {
        img: imgTeam5,
        name: "Linh Nguyễn",
        job: "Art Director"
    }, {
        img: imgTeam6,
        name: "Albus",
        job: "Community Manager"
    }, {
        img: imgTeam7,
        name: "Henry",
        job: "Community Manager"
    }, {
        img: imgTeam8,
        name: "Yến Nguyễn",
        job: "Asset Integration Manager"
    }, {
        img: imgTeam9,
        name: "Chương Nguyễn",
        job: "Lead Level Design"
    }, {
        img: imgTeam10,
        name: "Ninh Lê",
        job: "Lead 3D Designer"
    }, {
        img: imgTeam11,
        name: "Ninh Lê",
        job: "Lead 3D Designer"
    }, {
        img: imgTeam12,
        name: "Ninh Lê",
        job: "Lead 3D Designer"
    }, {
        img: imgTeam13,
        name: "Ninh Lê",
        job: "Lead 3D Designer"
    }, {
        img: imgTeam14,
        name: "Ninh Lê",
        job: "Lead 3D Designer"
    }]

    return <Wrap id="team">
        <div className="tittle-row">
            <span className="size-4 color-white">OUR&nbsp;<span className="size-4 text-gradient">TEAM</span></span>
        </div>
        <div className="container">
            {dataFeature.map((item, index) => <div key={index} className="fe-item appear">
                <div className="fi-img">
                    <img src={item.img} alt="" />
                </div>

                <span className='size-2 color-gold fi-name'>{item.name}</span>
                <span className='size-1 color-white fi-job'>{item.job}</span>
            </div>)}
        </div>
        <div className="footer">
            <Footer />
        </div>
    </Wrap>

}

export default Team
const Wrap = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 120px 0;
    margin-top: -120px;
    background-image: url(${bgTeam});
    background-size: cover;
    background-position: bottom center;
    height: fit-content;
    .footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: fit-content;
    }
    .tittle-row {
        margin-bottom: 30px;
    }
    .container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .fe-item {
            width: calc((100% - 120px) / 5);
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-right: 30px;
            margin-bottom: 30px;
            align-self: stretch;
            background: #1A1A1A;
            border: 2px solid #333333;
            border-radius: 10px;
            padding: 17px;
            position: relative;
            &::after {
                content: "";
                position: absolute;
                top: calc(50% - 1px);
                left: calc(50% - 1px);
                transform: translate(-50%, -50%);
                opacity: 0;
                transition: 0.3s;
                width: 113%;
                height: 111%;
                z-index: 0;
                background-image: url(${frameTeam});
                background-size: 100% 100%;
            }
            &:hover {
                &::after {
                    opacity: 1;
                }
            }
            &:nth-child(5n + 5) {
                margin-right: 0;
            }
            .fi-img {
                margin: 11px 23px 27px 23px;
                width: 100%;
                position: relative;
                padding-top: 100%;
                z-index: 1;
                > img {
                    top: 50%;
                    left: 50%;
                    position: absolute;
                    width: 100%;
                    height: auto;
                    transform: translate(-50%, -50%);
                    border-radius: 50%;
                }
            }
            .fi-name {
                text-align: center;
            }
            .fi-job {
                text-align: center;
            }
            ${breakpointsMedias.max1199} {
                width: calc((100% - 90px) / 4);
                &:nth-child(5n + 5) {
                    margin-right: 30px;
                }
                &:nth-child(4n + 4) {
                    margin-right: 0;
                }
            }
            ${breakpointsMedias.max991} {
                width: calc((100% - 60px) / 3);
                &:nth-child(4n + 4) {
                    margin-right: 30px;
                }
                &:nth-child(3n + 3) {
                    margin-right: 0;
                }
            }
            ${breakpointsMedias.max767} {
                width: calc((100% - 32px) / 3);
                padding: 10px;
                .fi-img {
                    margin: 8px 24px 23px 24px;
                }
                margin-right: 16px;
                &:nth-child(5n + 5) {
                    margin-right: 16px;
                }
                &:nth-child(4n + 4) {
                    margin-right: 16px;
                }
                &:nth-child(3n + 3) {
                    margin-right: 0;
                }
            }
            ${breakpointsMedias.max639} {
                width: calc((100% - 16px) / 2);
                &:nth-child(3n + 3) {
                    margin-right: 16px;
                }
                &:nth-child(2n + 2) {
                    margin-right: 0;
                }
            }
        }
    }
    ${breakpointsMedias.max1199} {
        margin-top: 0;
    }
    ${breakpointsMedias.max991} {
        background-image: url(${bgIntro});
        background-position: center;
    }
    ${breakpointsMedias.max767} {
        padding: 72px 0 130px 0;

    }
`