import styled from 'styled-components'
import { breakpointsMedias } from '../../configs/breakpoints'
import iconDiscord from 'assets/images/icon-discord.svg'
import iconTele from 'assets/images/icon-telegram.svg'
import iconTele2 from 'assets/images/icon-tele-2.svg'
import iconTwitter from 'assets/images/icon-twitter.svg'
import iconMedium from 'assets/images/icon-medium.svg'
import { Link } from 'react-router-dom'


export const SOCIAL_LIST = [
    {
        link: "https://t.me/MetaStrikeChat",
        icon: iconTele,
    },
    {
        link: "https://t.me/MetaStrike",
        icon: iconTele2,
    },
    {
        link: "https://twitter.com/MetastrikeHQ",
        icon: iconTwitter,
    },
    {
        link: "https://blog.metastrike.io/",
        icon: iconMedium,
    },
    {
        link: "https://discord.gg/J9WjzNmHpa",
        icon: iconDiscord,
    },
];

export const docsLink = [
    {
        link: "/terms",
        text: "Terms and Conditions",
    },
    {
        link: "/privacy",
        text: "Privacy Policy",
    },
    {
        link: "/cookies",
        text: "Cookies Policy",
    },
];


const Footer = () => {

    return (
        <Wrap className=''>
            <div className="container">
                <div className="social-list">
                    {SOCIAL_LIST.map((item, index) => <a href={item.link} target='_blank' rel="noreferrer" key={index} className='sl-item'>
                        <img src={item.icon} alt="" />
                    </a>)}
                </div>
                <div className="docs-list">
                    {docsLink.map((item, index) => <Link to={item.link} key={index} className='dl-item'>
                        <span className="size-0 color-black">{item.text}</span>
                    </Link>)}
                </div>
            </div>
        </Wrap>
    )
}

export default Footer

const Wrap = styled.div`
    background: linear-gradient(90deg, #FFFC02 0%, #E88422 100%);
    padding: 16px 0;
    display: flex;
    justify-content: center;
    z-index: 0;
    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .social-list {
            display: flex;
            align-items: center;
            .sl-item {
                width: 29px;
                height: auto;
                margin-right: 24px;
                display: flex;
                > img {
                    width: 100%;
                    height: auto;
                }
                &:nth-child(2) {
                        width: 43px;
                }
            }
        }
        .docs-list {
            display: flex;
            align-items: center;
            .dl-item {
                margin-left: 40px;
            }
        }
    }
   
    ${breakpointsMedias.max767} {
        padding: 12px 0;
        .container {
            display: flex;
            align-items: center;
            flex-direction: column-reverse;
            align-items: center;
            .social-list {
                display: flex;
                align-items: center;
                .sl-item {
                    width: 23px;
                    height: auto;
                    margin-right: 19px;
                    display: flex;
                    &:last-child {
                        margin-right: 0;
                    }
                    &:nth-child(2) {
                        width: 36px;
                    }
                }
            }
            .docs-list {
                margin-bottom: 12px;
                .dl-item {
                    margin-left: 34px;
                    text-align: center;
                    &:first-child {
                        margin-left: 0;
                    }
                  
                }
            }
        }
    }
`
