import { Link, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import imgMenu from 'assets/images/img-menu.png'
import { useTranslation } from 'react-i18next';

import configColor from 'configs/configColor'
import { breakpointsMedias } from 'configs/breakpoints'
import Button from 'components/core/Button'
import { useWidthScreen } from 'helpers/useScreen';

interface IMenu {
    onClose: () => void
}

export const MENU_LIST = [
    {
        text: "about",
        link: "/#intro",
    },
    {
        text: "game concept",
        link: "/#mode",
    },
    {
        text: "white paper",
        link: "https://metastrike.gitbook.io/metastrike-a-new-gen-fps-blockchain-based-game/",
        exlink: true
    },
];

const Menu = ({ onClose }: IMenu) => {
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const { width } = useWidthScreen()

    return (
        <Wrap >
            <div className="menu-img">
                {/* <img src={imgMenu} alt="" /> */}
            </div>
            <div className={`menu-list`}>
                {
                    MENU_LIST.map((item, index) =>
                        <a href={item.link}
                            key={index}
                            className={`menu-item ${pathname.split("/")[1] === item.text ? "menu-item-active" : ""}`}
                            onClick={() => {
                                onClose()
                            }}
                            target={item.exlink ? "_blank" : ""}
                        >
                            <span className={`size-2 color-white`}>{t(item.text)}</span>
                        </a>)
                }
                <a href="https://stake.metastrike.io/" target='_blank' className="bt-stake" rel="noreferrer">
                    <Button sizeBt={width >= 1200 ? 1 : 2} text='Staking' className='' />
                </a>
                <a href="https://marketplace.metastrike.io/" target='_blank' className="bt-market" rel="noreferrer">
                    <Button sizeBt={2} text='Marketplace' className='' />
                </a>
            </div>

        </Wrap>
    )
}

export default Menu

const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background-color: #1A1A1A;
    position: relative;
    max-width: 503px;
    padding: 57px 20px;
    .menu-img {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(${imgMenu});
        background-size: cover;
    }
   
    .menu-list {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;
        z-index: 0;
        align-items: flex-end;
        .menu-item {
            /* margin-bottom: 16px; */
            cursor: pointer;
            color: ${configColor.lightWhite};
            display: flex;
            align-items: center;
            text-transform: uppercase;
            width: fit-content;
            margin-bottom: 0px;
            margin-top: 16px;
            > span {
                display: flex;
                align-items: center;
            }
            &:hover{
                > span {
                    color: ${configColor.colorPrimary};
                }
            }
        }
        .bt-stake,
        .bt-market {
            margin-top: 16px;
        }
        .menu-item-active {
            > span {
               color: ${configColor.colorPrimary};
            }
        }
        
    }
    ${breakpointsMedias.min1200} {
        flex-direction: row;
        background-color: transparent;
        max-width: unset;
        background: none;
        padding: 0;
        .menu-img {
            display: none;
        }
        .menu-list {
            border: none;
            flex-direction: row;
            align-items: center;
            padding: 0;
            .menu-item {
                margin: 0 20px;
                &:first-child {
                    margin-left: auto;
                }
            }
            .bt-stake {
                margin-left: auto;
            }
            .bt-market {
                margin-left: 18px;
            }
        }
    }
    ${breakpointsMedias.min1360} {
        .menu-list {
            .menu-item {
                margin: 0 20px;
            }
        }
    }
`

interface ISocicalItem {
    active: string
}

const SocicalItem = styled.div`
    background-color: #FFFFFF;
    -webkit-mask: url(${({ active }: ISocicalItem) => active}) no-repeat center;
    mask: url(${({ active }: ISocicalItem) => active}) no-repeat center;
    mask-size: contain;
    /* transition: 0.6s ease-in-out; */
    &:hover {
        background: ${configColor.colorPrimary};
    }
`