import LoadingScreen from 'components/core/LoadingScreen'
import Cookies from 'pages/cookies/Cookies'
import Privacy from 'pages/privacy/Privacy'
import Team from 'pages/team/Team'
import Terms from 'pages/terms/Terms'
import { lazy, Suspense } from 'react'
import { useRoutes } from 'react-router-dom'
const Homepage = lazy(() => import("../pages/home/Homepage"))
const Routers = () => {
    const routes = useRoutes([
        {
            path: '/',
            element:
                <Suspense fallback={<LoadingScreen />}>
                    <Homepage />
                </Suspense>
        },
        {
            path: '/team',
            element:
                <Suspense fallback={<LoadingScreen />}>
                    <Team />
                </Suspense>
        },
        {
            path: '/terms',
            element:
                <Suspense fallback={<LoadingScreen />}>
                    <Terms />
                </Suspense>
        },
        {
            path: '/privacy',
            element:
                <Suspense fallback={<LoadingScreen />}>
                    <Privacy />
                </Suspense>
        },
        {
            path: '/cookies',
            element:
                <Suspense fallback={<LoadingScreen />}>
                    <Cookies />
                </Suspense>
        }
    ])
    return routes
}

export default Routers