import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import LoadingSpinner from './LoadingSpinner';
import configColor from 'configs/configColor';
import { breakpointsMedias } from 'configs/breakpoints';
import bgBt1 from "assets/images/bg-bt-1.png"
import bgBt2 from "assets/images/bg-bt-2.png"
import bgBt3 from "assets/images/bg-bt-3.png"
import bgBt4 from "assets/images/bg-bt-4.png"

interface IB extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    text: string,
    isLoading?: boolean,
    className?: string,
    disabled?: boolean,
    sizeBt?: 1 | 2 | 3 | 4
}

const Button = ({ text, isLoading = false, sizeBt = 1, className, disabled = false, ...props }: IB) => {
    const { t } = useTranslation();
    return (
        <Wrap className={`${isLoading ? "bt-loading" : ""} bt-${sizeBt} ${className}`} disabled={disabled || isLoading} {...props}>
            <div className="">
                <span className={`color-black size-2`}>{!!isLoading ? <LoadingSpinner />
                    : <>
                        {t(text)}
                    </>}</span>
            </div>

        </Wrap>
    )
}
export default Button

const Wrap = styled.button`
    /* max-width: 100%; */
    width: 100%;
    height: 55px;
    position: relative;
    &::before {
            position: absolute;
            content: "";
            width: 100%;
            height: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            box-shadow: 0px 0px 40px #F2BD02, 0px 8px 24px #f2be029e;
            z-index: 0;
            opacity: 0;
            transition: 0.3s;
        }
    > div {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 10px;
        padding-right: 7px;
        background-size: 100% 100% !important;
        z-index: 1;
        position: relative;
    }
    &.bt-1 {
        width: 113px;
        > div {
            background-image: url(${bgBt1}); 
        }
       
    }
    &.bt-2 {
        width: 156.46px;
        > div {
            background-image: url(${bgBt2}); 
        }
       
    }
    &.bt-3 {
        width: 220px;
        > div {
            background-image: url(${bgBt3}); 
        }
       
    }
    &.bt-4 {
        width: 300px;
        > div {
            background-image: url(${bgBt4}); 
        }
        
    }
   
   
    /* &:active,
    &:focus, */
    &:hover {
        &::before {
           opacity: 1;
        }
        /* > span {
            color: ${configColor.colorWhite};
            text-shadow: 0px 0px 32px rgb(221, 207, 167), 0px 8px 24px #7c7373;
        } */
    }
    cursor: pointer;

    &:disabled{
        opacity: 0.4;
        cursor: not-allowed;
    }
    &.bt-loading {
        opacity: 0.4;
        cursor: not-allowed;
    }
    ${breakpointsMedias.max1199} {
    
    }
`