import bgTeam from "assets/images/bg-team.png"
import styled from 'styled-components'
import { breakpointsMedias } from "configs/breakpoints"
import Footer from "components/Footer/Footer"
import bgIntro from "assets/images/bg-intro.png"

const Terms = () => {
    return <Wrap id="team">
        <div className="tittle-row">
            <span className="size-4 color-white text-center">Terms and Conditions &nbsp;<span className="size-4 text-gradient">NFT Trading Market</span></span>
        </div>
        <div className="container">
            <div className="team-pvc">
                <h3 className="size-3 color-gold" >1.Acceptance</h3>
                <p>By clicking the “Sign Up” button, completing the account registration process and using the NFT Platform you confirm that you understand and agree to these terms and conditions, together with any documents that may be expressly referred to and are incorporated by reference (“Terms”).<br />
                    These Terms constitute a legal agreement between you and the Metatech Ltd. and govern your access to and use of the NFT Platform, including any content, functionality, and services offered on it.
                </p>
                <p>The Company reserves the right to change or modify these terms at any time and at our sole discretion. You agree and understand that by accessing or using the NFT Platform following any change to these Terms, you are regarded as having agreed to the revised Terms.</p>
                <h3 className="size-3 color-gold" >2.Definitions</h3>
                <p>In these Terms:</p>
                <p><strong className="size-2 color-gold">“Non-fungible token” “NFT”</strong><span>: means Ethereum-based tokens complying with the Binance Smart Chain (BSC). NFTs are intended to be “non-fungible” tokens representing a unique Collectible; however, certain NFTs may be fungible with one another (i.e., all such NFTs are associated with the same Collectible Metadata) while being non-fungible with other NFTs (i.e., such NFTs are associated with different Collectible Metadata).</span></p>
                <p><strong className="size-2 color-gold">“Fungible items”</strong><span>: can be exchanged because their value defines them rather than their unique properties. For example, ETH or dollars are fungible because 0.1 ETH / $1 USD is exchangeable for another 0.1 ETH / $1 USD.
                </span></p>
                <p><strong className="size-2 color-gold">“Applicable Law”</strong><span>: any law, rule, statute, subordinate legislation, regulation, by-law order, ordinance, protocol, code, guideline, treaty, policy, notice, direction or judicial, arbitral, administrative, ministerial or departmental judgment, award, decree, treaty, directive, or other requirement or guideline published or in force at any time which applies to or is otherwise intended to govern or regulate any person (including all parties to this Terms), property, transaction, activity, event or other matter, including any rule, order, judgment, directive or other requirement or guideline issued by any governmental or regulatory authority;
                </span></p>
                <p><strong className="size-2 color-gold">“Sale Item”</strong>
                    <span>
                        means any one or more of the following without limitation:
                        (a) any art (including without limitation, designs, drawings, prints, images in any form or media, including without limitation videos and photographs);<br />
                        (b) audio files;<br />
                        (c) collectibles;<br />
                        (d) memorabilia;<br />
                        (e) game assets.<br />
                        “we/us/our” means the Company;<br />
                        “you/your” means the user of the NFT Platform or website;
                    </span>
                </p>
                <h3 className="size-3 color-gold" >3.Eligibility</h3>
                <p>
                    The Company has sole and absolute discretion to allow or disallow your access to the NFT Platform.
                    By agreeing to these Terms, you represent and warrant that:<br />
                    (i) You are at least 18 years of age;<br />
                    (ii) You have the full right, power, and authority to agree to these Terms;<br />
                    (iii) You are not subject to any financial sanctions, embargoes or other restrictive measures imposed by the United Nations, European Union, any EU country, UK Treasury or US Office of Foreign Assets Control (OFAC), or any governmental authority in any jurisdiction in which the NFT Platform is available;<br />
                    (iv) You are not a citizen or resident of any of the following countries: Belarus, Central African Republic, Cote d’Ivoire (Ivory Coast), Cuba, Democratic Republic of the Congo, Iran, Iraq, Lebanon, Liberia, Libya, Myanmar (Burma), North Korea, Somalia, Sudan, Syria, Zimbabwe.<br />
                    (v) You are not impersonating any other person;<br />
                    (vi) You will not use the NFT Platform if any Applicable Laws in your country prohibit you from doing so in accordance with these Terms;<br />
                    (vii) You are compliant with all Applicable Laws to which you are subject;<br />
                    (viii) You have read, understood and agreed to our Privacy Policy and Cookie Policy.<br />
                </p>
                <h3 className="size-3 color-gold" >4.Company Account</h3>
                <p>You must create an account (“Account”) to use the NFT Platform. To create an account, we will require you to provide certain information about yourself and we may, in our sole discretion, require you to provide further information and/or documents at any stage during your use of the NFT Platform. We may, in our sole discretion, refuse, decline, suspend or disable your access or use of the NFT Platform.</p>
                <h3 className="size-3 color-gold" >5.Changes to the NFT Platform</h3>
                <p>We may in our absolute and sole discretion change, update, amend, remove, or discontinue any part of the NFT Platform, the services and the Content at any time without prior notice to you.</p>
                <h3 className="size-3 color-gold" >6.Fees</h3>
                <p>By buying and selling NFTs, including through the Auction process, on the NFT Platform, you agree to pay all applicable fees as stipulated in the FAQ and on the checkout screen at the time of your purchase. You authorize the Company to automatically deduct fees directly from payments to you and/ or add fees to your payments to the Company where applicable.</p>
                <h3 className="size-3 color-gold" >7.Your Use of the NFT Platform and Conduct</h3>
                <p>
                    We hereby grant you a limited, non-exclusive, non-transferable, revocable license to access and use the NFT Platform and Content. Our grant of such license is subject to the following conditions. You undertake not to (and shall not, knowingly or otherwise, authorize, allow or assist any other party to):<br />
                    (i) Use the NFT Platform, or your Account to conduct electronic spamming or otherwise distribute any unsolicited or unauthorized advertising, promotional or marketing material, junk or chain messages;<br />
                    (ii) Use the NFT Platform, or your Account to perform unlawful activities that violates any Applicable Laws (including but not limited to money laundering, terrorism financing and/or fraudulent activities) or immoral activities;<br />
                    (iii) Use the NFT Platform, or your Account to engage in any activity which operates to defraud the Company, other users, or any other person, or to provide any false, inaccurate, or misleading information to the Company;<br />
                    (iv) Use the NFT Platform, or your Account to upload content that contains or is infected with viruses, malicious codes, Trojan horses, is immoral or illegal or contains any other harmful or deleterious program;<br />
                    (v) Modify or adapt the whole or any part of the NFT Platform or incorporate the NFT Platform/Site into any other program or application;
                    (vi) Disassemble, decompile, reverse-engineer or otherwise attempt to derive the source code, object code, underlying concepts, ideas and algorithms of the NFT Platform or any components thereof;<br />
                    (vii) Use the NFT Platform, or your Account in any manner that would lead to infringement of our, our Affiliates’ or any third party’s intellectual property rights, including without limitation any copyright, patent or trademark. You undertake not to take or attempt to take any action or claim ownership of any property that infringes or would infringe upon our intellectual property interests;<br />
                    (viii) Use the NFT Platform, or your Account in a way that could damage, disable, impair or compromise the NFT Platform or the provision of the NFT Platform or interfere with other users or affect the reputation of the Company;<br />
                    (ix) To take any action to gain or attempt to gain unauthorized access to the account or wallets of other users;<br />
                    (x) Take any action that imposes an unreasonable or disproportionately large burden or load on the Company infrastructure (including, but without limitation to our servers, networks, data centres and related or like equipment) and detrimentally interfere with, intercept or expropriate any system, data or information belonging to other users of the NFT Platform;<br />
                    (xi) Engage in any other activities deemed inappropriate by us or which is in contravention of these Terms or any Applicable Laws;<br />
                    (xii) Provide false, inaccurate, incomplete or misleading information to the Company or any of its Affiliates or third-party services providers; and/or<br />
                    (xiii) Use the NFT Platform, or your Account to engage in any lottery, bidding fee auctions, contests, sweepstakes, or other games of chance.
                </p>
                <h3 className="size-3 color-gold" >8.User Content</h3>
                <p>
                    The NFT Platform allows (i) users to create a profile where they can post information about themselves, display their NFTs, and sell NFTs they own (ii) artists or creators of NFTs (“Creator”) to put their NFTs up for sale (which may be via auction (“Auction”, please refer to clause 9 for more information) (together the “User Content”).<br />
                    Any information you post on the NFT Platform as a Creator will be considered non-confidential. By providing any User Content on the NFT Platform, you grant us and our affiliates and our respective licensees, successors, and assigns the right to use, reproduce, modify, perform, display, distribute, retransmit, publish, broadcast, and otherwise disclose to third parties any such material for any purpose. You represent and warrant that (1) you own and control all rights in and to your User Content and have the right to grant such licenses to us and our affiliates and our respective licensees, successors, and assigns; and<br />
                    (2) all of your User Content do and will comply with these Terms.<br />
                    You understand and agree that you are responsible for any User Content you submit or contribute, and you, and not the Company, have full responsibility for such content, including its legality, reliability, accuracy, and appropriateness. We are not responsible or liable to any third party for the content, accuracy, or appropriateness of any User Content posted by you or any other user on the NFT Platform.<br />
                    If you are a Creator, you hereby grant the Company the right to use your name and image for marketing or promotional purposes and agree that we may use or modify images from the NFTs that you create for marketing or promotional purposes. You also agree that we can use your biography and other public information about you to promote the NFTs that you create.<br />
                    If you are a Creator, you agree that you will not infringe on the intellectual property of others and will not coordinate pricing of any NFTs with other Creators.<br />
                    We reserve the right, in our absolute sole discretion, to prohibit you from uploading any NFTs to the NFT Platform. We are not required to monitor any User Content, but we may in our sole discretion, remove any User Content at any time and for any reason without notice. The Company may monitor the User Content to detect and prevent fraudulent activity or violation of these Terms.
                </p>
                <h3 className="size-3 color-gold" >9.Intellectual Property</h3>
                <p>
                    Unless otherwise indicated by us, and except to the extent of the User Content, NFT Platform, all content, and other materials contained therein, including, without limitation, the Company logo, and all designs, text graphics, pictures, information, data, software, and files relating to the Company and NFT Platform (the “Content”) are the proprietary property of the Company or our affiliates, licensors, or users, as applicable.<br />
                    The Company logo and any Company product or service names, logos, or slogans that may appear on the NFT Platform or elsewhere are the proprietary property of the Company and may not be copied, imitated or used, in whole or in part, without our prior written permission.<br />
                    Unless otherwise stated, you may not use any Content without our express written permission.<br />
                    We reserve the right to suspend or terminate any Account that has actually or allegedly infringed upon any person’s intellectual property rights.
                </p>
                <h3 className="size-3 color-gold" >10.Sale by Auction</h3>
                <p>
                    You may sell and purchase NFTs through the Auction process. Your participation in the Auction is subject to the rules available on the NFT Platform FAQ.<br />
                    You may only participate in the Auction by linking your digital wallet, which must be a type supported by the Company, to your Account.<br />
                    The Company may pause, cancel, or discontinue your Auction transactions at its sole discretion without liability.<br />

                </p>
                <h3 className="size-3 color-gold" >11.Your Ownership of the NFT</h3>
                <p>
                    Apart from the Content, all other copyrights, trademarks, product names, and logos on the NFT Platform relating to and including the NFTs and User Content, are the property of their respective owners and may not be copied, imitated, or used, in whole or in part, without the permission of the applicable intellectual property right owner.<br />
                    When you buy an NFT on the NFT Platform, you own the NFT and have the right to sell or give away the NFT. If the NFT is associated with a Sale Item, you will have a worldwide, perpetual, exclusive, transferable, licence to use, copy, and display the Sale Item for your NFT, for so long as you own the NFT, solely for the following purposes: (a) for your own personal, non-commercial use; (b) as part of the NFT Platform that permits the purchase, sale and display of your NFT; (c) as part of a third party website or application that permits the inclusion, involvement, storage, or participation of your NFT.<br />
                    Without limiting the foregoing, if you believe that third-party material hosted by the Company on the NFT Platform or otherwise infringes your copyright or trademark rights, please file a notice of infringement by contacting:sondinh@metastrike.io
                </p>
                <h3 className="size-3 color-gold" >12.Data Protection / Privacy</h3>
                <p>
                    By using the NFT Platform, you confirm that you have read and understood our Privacy Notice and understand how we collect, use, disclose and share amongst ourselves your Personal Data and disclose such Personal Data to our authorised service providers and relevant third parties. We will only share your Personal Data in order to facilitate and administer your use of the NFT Platform or otherwise if required by law. Such a data controller will manage and protect your personal data in accordance with all applicable data protection laws. For full and comprehensive information about when and why we collect personal information about you, how we use it, the conditions under which we may disclose it and how we keep it secure, please refer to our Privacy Policy, which is accessible at https://universeisland.games/privacy/.<br />
                    We reserve the right at any time to satisfy our internal requirement as to your Personal Data (for example, by requesting relevant original documents) including for the purposes of preventing fraud and/or anti-money laundering and counter-terrorist financing purposes.
                </p>
                <h3 className="size-3 color-gold" >13.Limitation of Services / Account Closure / Termination</h3>
                <p>
                    We reserve the right, without notice and in our sole discretion, to terminate or suspend your access to or use of the NFT Platform and any Content and/or close your Account, at any time for any reason but in particular, if we suspect in our sole discretion that<br />
                    1. your Account is being used for illegal activity;<br />
                    2. you have concealed or provided false information;<br />
                    3. you have engaged in fraudulent activity; and/or (iv) you have engaged in activity in violation of these Terms.<br />
                    If the Metatech Ltd. is holding funds in your Account and has no record of your use of the NFT Platform for several years, we may be required, upon passage of applicable time periods, to report these funds as unclaimed property in accordance with the abandoned property and escheat laws. If this occurs, we will use reasonable efforts to give you written notice. If you fail to respond within seven business days or the period as required by Applicable Law, we may be required to deliver any such funds to the applicable jurisdiction as unclaimed property. We reserve the right to deduct a reasonable administrative fee from such unclaimed funds, as permitted by Applicable Law.
                </p>
                <h3 className="size-3 color-gold" >14.Risks</h3>
                <p>
                    You understand and agree that your access and use of the NFT Platform is subject to certain risks including without limitation:<br />
                    (i) Price and liquidity of blockchain assets, including the NFTs, are extremely volatile and may be subject to fluctuations.<br />
                    (ii) Fluctuations in the price of other digital assets could materially and adversely affect the NFTs;<br />
                    (iii) Legislative and regulatory changes or actions may adversely affect the use, transfer, and value of the NFTs;<br />
                    (iv) NFTs are not legal tender and are not back by any government;<br />
                    (v) Transactions involving NFTs may be irreversible, and losses due to fraudulent or accidental transactions may not be recoverable.<br />
                    (vi) The value of NFTs may be derived from the continued willingness of market participants to exchange fiat currency or digital assets for NFTs, and therefore the value of NFTs is subject to the potential for permanent or total loss of value should the market for NFTs disappear.<br />
                    (vii) NFTs are subject to the risk of fraud, counterfeiting, cyber-attacks and other technological difficulties which may prevent access to or use of your NFTs.<br />
                    You understand and agree that you are solely responsible for determining the nature, potential value, suitability, and appropriateness of these risks for yourself. The Company does not give any advice or recommendations regarding the NFTs. You understand and agree that you access and use the NFT Platform at your own risk. You understand and agree that the Company will not be responsible for any communication failures, disruptions, errors, or distortions you may experience when using the NFTs or the NFT Platform.
                </p>
                <h3 className="size-3 color-gold" >15.Taxes</h3>
                <p>You agree that you are solely responsible for determining what, if any, taxes apply to your NFT transactions on the NFT Platform. Neither the Metatech Ltd. nor any other Party is responsible for determining the taxes that may apply to your NFT transactions.</p>
                <h3 className="size-3 color-gold" >16.Disclaimers</h3>
                <p>
                    Creators may engage in promotion of their respective User Content, including without limitation their Sale Item, through various communications channels such as their social media accounts. The Metatech Ltd. is not responsible for any such communications and/or promotional activities carried out by the Creators and will not be liable to you in relation to any such communications and/or promotional activities.<br />
                    You bear full responsibility for verifying the identity, legitimacy, and authenticity of assets you purchase on the NFT Platform. Notwithstanding indicators and messages that suggest verification, the Metatech Ltd. makes no claims about the identity, legitimacy, or authenticity of assets on the NFT Platform.<br />
                    Except as expressly provided to the contrary in writing by the Company, the NFT Platform, content contained therein, and the NFTs listed therein are provided on an “as is” and “as available” basis without warranties or conditions of any kind, either express or implied. The Company (and its suppliers) make no warranty that the NFT Platform will (1) meet your requirements; (2) be available on an uninterrupted, timely, secure, or error-free basis; or (3) be accurate, reliable, complete, legal, or safe.<br />
                    The Metatech Ltd. will not be liable for any loss of any kind from any action taken or taken in reliance on material or information contained on the NFT Platform. The Company does not represent or warrant that any content on the NFT Platform is accurate, complete, reliable, current or error-free.
                    While the Metatech Ltd. attempts to make your access to and use of the NFT Platform and content safe, the Company does not represent or warrant that the NFT Platform, content, any NFTs listed on the NFT Platform or any other parts of the NFT Platform are free of viruses or other harmful components. We cannot guarantee the security of any data that you disclose online. You acknowledge and accept the inherent security risks of providing information and dealing online over the Internet. We will not be responsible for any breach of security unless it is due to our gross negligence.<br />
                    We will not be responsible or liable to you for any loss and take no responsibility for, and will not be liable to you for, any use of the NFTs including but not limited to, any losses, damages, or claims arising from: (1) user error such as if you forget your password(s), incorrect transactions, or mistyped addresses; (2) server failure or data loss; (3) corrupted wallet files; (4) loss of NFTs.<br />
                    TO THE FULLEST EXTENT PROVIDED BY LAW, THE COMPANY HEREBY DISCLAIMS ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT AS TO THE NFT PLATFORM AND CONTENT CONTAINED THEREIN. THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.
                </p>
                <h3 className="size-3 color-gold" >17.Limitation of Liability</h3>
                <p>
                    TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL THE COMPANY BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES ARISING FROM THESE TERMS, THE NFT PLATFORM, PRODUCTS OR THIRD PARTY SITES AND PRODUCTS, OR FOR ANY DAMAGES RELATED TO LOSS OF REVENUE, LOSS OF PROFITS, LOSS OF BUSINESS OR ANTICIPATED SAVINGS, LOSS OF USE, LOSS OF GOODWILL, OR LOSS OF DATA, AND WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE, EVEN IF FORESEEABLE AND EVEN IF THE COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ACCESS TO, AND USE OF THE NFT PLATFORM, PRODUCTS OR THIRD-PARTY SITES AND PRODUCTS ARE AT YOUR OWN DISCRETION AND RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA RESULTING THEREFROM. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN NO EVENT SHALL THE MAXIMUM AGGREGATE LIABILITY OF THE COMPANY ARISING OUT OF OR IN ANY WAY RELATED TO THESE TERMS, THE ACCESS AND USE OF THE NFT PLATFORM, CONTENT NFTS OR ANY PRODUCT OR SERVICES PURCHASES ON THE NFT PLATFORM EXCEED US$100. THE FOREGOING LIMITATIONS OF LIABILITY SHALL NOT APPLY TO LIABILITY OF THE COMPANY FOR PERSONAL INJURY CAUSED BY THE COMPANY’S NEGLIGENCE OR ANY INJURY CAUSED BY THE COMPANY’S FRAUD OR FRAUDULENT MISREPRESENTATION.
                </p>
                <h3 className="size-3 color-gold" >18.Indemnification</h3>
                <p>
                    To the fullest extent permitted by Applicable Law, you agree to indemnify, defend and hold harmless the Company and our past, present and future employees, officers, directors, contractors, consultants, equity holders, suppliers, vendors, service providers, parent companies, subsidiaries, affiliates, agents, representatives, predecessors, successors and assigns (individually and collectively the “Company Parties”), from and against all actual or alleged third party claims, damages, awards, judgments, losses, liabilities, obligations, penalties, interest, fees, expenses (including, without limitation, court costs, costs of settlement and costs of pursuing indemnification and insurance), of every kind and nature whatsoever, whether known or unknown, foreseen or unforeseen, matured or unmatured, or suspected or unsuspected, in law or equity, whether in tort, contract or otherwise (collectively, “Claims”), including, but not limited to, damages to property or personal injury, that are caused by, arise out of or are related to (a) your use or misuse of the NFT Platform Content or NFTs, (b) your breach of these Terms, and (c) your breach or violation of the rights of a third party, including another user or third party service provider. You agree to promptly notify the Company of any third-party Claims and cooperate with the CompanyParties in defending such Claims. You further agree that the Company Parties shall have control of the defense or settlement of any third-party Claims.<br />
                    THIS INDEMNITY IS IN ADDITION TO, AND NOT IN LIEU OF, ANY OTHER INDEMNITIES THAT MAY BE SET FORTH IN A WRITTEN AGREEMENT BETWEEN YOU AND THE COMPANY
                </p>
                <h3 className="size-3 color-gold" >19.Amendment and Variation</h3>
                <p>
                    These Terms may from time to time be updated or amended. We will post any such updates on the NFT Platform. Such updated Terms as posted will take effect immediately unless otherwise indicated. You should regularly check the NFT Platform to inform yourself of any such changes. In addition, we may at any time change, add or remove any feature or functionality of the NFT Platform without prior notice. By continuing to use the NFT Platform after any such changes have taken effect, you are indicating your acceptance of the updated or amended Terms as well as your acceptance of the updated NFT Platform. If you do not wish to be bound by any changes or amendments to these Terms then you should stop using the NFT Platform immediately.
                </p>
                <h3 className="size-3 color-gold" >20.Transfer, Assignment or Delegation</h3>
                <p>Unless otherwise stated herein, these Terms, and any rights and obligations and licenses granted hereunder, are limited, revocable, non-exclusive and personal to you and therefore may not be transferred, assigned or delegated by you to any third-party without our written consent, but may be transferred, assigned or delegated by us without notice and restriction, including without limitation to any of the entities within the Company group, or to any successor in interest of any business associated with the NFT Platform. Any attempted transfer or assignment in violation hereof shall be null and void.</p>
                <h3 className="size-3 color-gold" >21.Severability</h3>
                <p>If any provision of these Terms shall be found by any court or administrative body of competent jurisdiction to be invalid or unenforceable, the invalidity or unenforceability of such provision shall not affect the other provisions of these Terms and all provisions not affected by such invalidity or unenforceability shall remain in full force and effect. Such provision will be changed and interpreted to accomplish the objectives of the provision to the greatest extent possible under any Applicable Laws.</p>
                <h3 className="size-3 color-gold" >22.Entire Agreement / Translation</h3>
                <p>
                    These Terms constitute the entire agreement between the parties regarding its subject matter and supersedes and invalidates all other prior representations, arrangements, understandings, and agreements relating to the same subject matter, (whether oral or in writing, express or implied). Each party acknowledges that in agreeing to these Terms it does not rely on any statement, representation, warranty, or understanding other than those expressly set out in these Terms.<br />
                    These Terms are concluded in the English language and all communications including any notices or information being transmitted shall be in English. In the event that these Terms or any part of it is translated (for any proceedings, for your convenience or otherwise) into any other language, the English language text of these Terms shall prevail.
                </p>
                <h3 className="size-3 color-gold" >23.Waiver</h3>
                <p>
                    These Terms shall not be waived in whole or in part except where agreed by the parties in writing.
                    The delay of enforcement or the non-enforcement of any of the terms of these Terms by any party shall not be construed as a waiver of any of the other rights of that party arising out of the breach or any subsequent breach of any of these Terms and no right, power or remedy conferred upon or reserved for any party in these Terms is exclusive of any other right, power or remedy available to that party and each such right, power or remedy shall be cumulative.
                </p>
                <h3 className="size-3 color-gold" >24.Notices and Communications</h3>
                <p>
                    By using the NFT Platform, you agree that we may provide you with notices or other communications, including marketing, relating to your use of the NFT Platform electronically: (a) via email (in each case to the address that you provide), SMS message, or telephone call (in each case to the phone number that you provide), or (b) by posting to the NFT Platform. For notices made by email, the date of receipt will be deemed the date on which such notice is transmitted. You will always be given the option to unsubscribe from receiving any marketing material from us.<br />
                    Notices to us should be sent electronically to sondinh@metastrike.io
                </p>
                <h3 className="size-3 color-gold" >25.Third Party Rights</h3>
                <p>
                    Other than the entities within the Company group, a person who is not a party to these Terms has no right to enforce any of these Terms.
                </p>
                <h3 className="size-3 color-gold" >26.Governing Law and Jurisdiction</h3>
                <p>
                    These Terms are governed by and shall be construed in accordance with the laws of the BVI without regard to any choice or conflict of laws rules. Any dispute, controversy, or claim, whether contractual or non-contractual, arising out of or in connection with these Terms, or the breach, termination or invalidity thereof, or any other issue which shall arise in virtue of these Terms, shall be referred to and finally settled by arbitration.
                </p>
            </div>
        </div>
        <div className="footer">
            <Footer />
        </div>
    </Wrap>

}

export default Terms
const Wrap = styled.div`
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    justify-content: center;
    padding: 120px 0;
    margin-top: -120px;
    background-image: url(${bgTeam});
    background-size: cover;
    background-position: bottom center;
    height: fit-content;
    .footer {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: fit-content;
    }
    .tittle-row {
        margin-bottom: 30px;
        text-align: center;
    }
    > .container {
        display: flex;
        align-items: center;
        flex-direction: column;
        p {
            margin-top: 10px;
        }
    }
    ${breakpointsMedias.max1199} {
        margin-top: 0;
    }
    ${breakpointsMedias.max991} {
        background-image: url(${bgIntro});
        background-position: center;
    }
    ${breakpointsMedias.max767} {
        padding: 72px 0 130px 0;

    }
`

